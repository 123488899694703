import axios from 'axios'
import { useContext, useEffect, useRef, useState } from 'react'
import AdminContext from '../../../contexts/Admin'
import { getMonthsLabel } from '../../Common/Utils/display'
import ChartWidget from '../Widgets/ChartWidget'
import CompactWidget from '../Widgets/CompactWidget'
import { getMembersUrl } from '../Config/APIurls'

const Dashboard = () => {
  const { config, reload, displayMainMessage, navigate, checkAuthToken } =
    useContext(AdminContext)
  const [widgetData, setWidgetData] = useState({
    membersWidget: {},
    membersStatistics: {},
    growthStatistics: {},
  })
  const [loading, setLoading] = useState({
    membersWidget: true,
    membersStatistics: true,
    growthStatistics: true,
  })

  const membersStatisticsDefault = {
    type: 'pie',
    label: 'Idiomas de los suscriptores',
    data: {
      labels: ['Inglés', 'Alemán', 'Español', 'Francés', 'Otros'],
      datasets: [
        {
          label: 'suscriptores',
          data: [],
          backgroundColor: [
            'rgba(54, 162, 235, 0.2)',
            'rgba(255, 99, 132, 0.2)',
            'rgba(255, 206, 86, 0.2)',
            'rgba(75, 192, 192, 0.2)',
            'rgba(75, 192, 75, 0.2)',
          ],
          borderColor: [
            'rgba(54, 162, 235, 1)',
            'rgba(255, 99, 132, 1)',
            'rgba(255, 206, 86, 1)',
            'rgba(75, 192, 192, 1)',
            'rgba(75, 192, 75, 1)',
          ],
          hoverOffset: 2,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: true,
          position: 'bottom',
          labels: {
            generateLabels: (chart) => {
              const datasets = chart.data.datasets
              return datasets[0].data.map(
                (data, i) => ({
                  text: `${chart.data.labels[i]} ${data}`,
                  fillStyle: datasets[0].backgroundColor[i],
                  index: i,
                }),
                this
              )
            },
          },
        },
      },
    },
  }
  const membersGraph = useRef(null)
  const growthStatisticsDefault = {
    type: 'line',
    label: 'Nuevos suscriptores',
    data: {
      labels: [],
      datasets: [
        {
          label: 'Members',
          backgroundColor: 'rgba(221, 58, 143, 0.4)',
          borderColor: 'rgba(221, 58, 143, 1)',
          borderWidth: 1,
          hoverBackgroundColor: 'rgba(255, 201, 27, 0.4)',
          hoverBorderColor: 'rgba(255, 201, 27, 1)',
          data: [],
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          display: false,
          position: 'bottom',
          labels: {
            font: {
              size: 10,
            },
          },
        },
      },
    },
  }
  const growthGraph = useRef(null)

  // GET MEMBERS STATISTICS
  useEffect(() => {
    const getWidgetsInfo = async () => {
      try {
        if (await !checkAuthToken()) return navigate('/login')
        const response = await axios.get(getMembersUrl, config)
        // # from LAST 7 DAYS
        const lastWeek = new Date()
        lastWeek.setDate(lastWeek.getDate() - 7)
        const members = [...response.data]
        // Members Count
        const membersCount = members.length
        // New Members Count
        const newMembersCount = members.filter(
          (member) => new Date(member.createdAt).valueOf() >= lastWeek.valueOf()
        ).length

        // Community Languages Statistics
        const statisticsData = { ...membersStatisticsDefault }
        const enCount = members.filter(
          (member) => member.locale === 'en'
        ).length
        const deCount = members.filter(
          (member) => member.locale === 'de'
        ).length
        const esCount = members.filter(
          (member) => member.locale === 'es'
        ).length
        const frCount = members.filter(
          (member) => member.locale === 'fr'
        ).length
        const otCount = members.filter(
          (member) =>
            member.locale !== 'en' &&
            member.locale !== 'fr' &&
            member.locale !== 'de' &&
            member.locale !== 'es'
        ).length
        statisticsData.data.datasets[0].data = [
          enCount,
          deCount,
          esCount,
          frCount,
          otCount,
        ]
        // Sign Up Evolution Statistics
        const growthData = { ...growthStatisticsDefault }
        // SET LAST SIX MONTHS
        growthData.data.labels = [...getMonthsLabel(5)]
        // Loop through the months
        for (let i = 0; i < 6; i++) {
          // Loop throught the months for each role
          const membersCount = members.filter(
            (member) =>
              new Date(member.createdAt).getMonth() ===
              new Date(growthData.data.labels[i]).getMonth()
          ).length
          growthData.data.datasets[0].data.push(membersCount)
        }
        // Update Widgets Data
        const updatedData = {
          membersWidget: {
            label: 'Suscriptores',
            value: membersCount,
            new: newMembersCount,
          },
          membersStatistics: { ...statisticsData },
          growthStatistics: { ...growthData },
        }

        setWidgetData((prev) => ({
          ...prev,
          ...updatedData,
        }))
        setLoading((prev) => ({
          ...prev,
          membersWidget: false,
          membersStatistics: false,
          growthStatistics: false,
        }))
      } catch (err) {
        displayMainMessage(
          {
            error: 1,
            message: err.status
              ? err.response.message
              : 'Algo salió mal al cargar los datos de los widgets',
            show: true,
          },
          5000
        )
      }
    }
    getWidgetsInfo()
  }, [reload])

  return (
    <div className='h-full flex flex-col w-full px-3 overflow-auto'>
      <div className='flex-none w-full'>
        <CompactWidget
          loading={loading.membersWidget}
          data={widgetData.membersWidget}
        />
      </div>
      <div className='flex flex-col lg:flex-row w-full'>
        <div className='flex w-full lg:w-1/2'>
          <ChartWidget
            loading={loading.membersStatistics}
            data={widgetData.membersStatistics}
            refVrb={membersGraph}
          />
        </div>
        <div className='flex w-full lg:w-1/2'>
          <ChartWidget
            loading={loading.growthStatistics}
            data={widgetData.growthStatistics}
            refVrb={growthGraph}
          />
        </div>
      </div>
      <div className='w-full flex flex-col md:flex-row bg-white'>
        <div className=' flex-none md:flex w-full  md:w-1/3'>
          <div className='w-full min-h-full px-2'></div>
        </div>
        <div className='flex-none md:flex w-full  md:w-2/3'>
          <div className='w-full min-h-full px-2'></div>
        </div>
      </div>
    </div>
  )
}

export default Dashboard

import { useField, useFormikContext } from 'formik'
import { at } from 'lodash'

const TextInput = ({
  defaultLabel,
  name,
  type,
  required,
  placeholder,
  styled,
}) => {
  const [field, meta] = useField({ name, defaultLabel })
  const [touched, error] = at(meta, 'touched', 'error')
  const { validateField, handleChange } = useFormikContext()

  const update = (e) => {
    handleChange(e)
    if (touched) {
      validateField(name)
    }
  }

  return (
    <>
      <input
        {...field}
        type={type}
        list={name}
        required={required}
        placeholder={placeholder}
        className={styled}
        autoComplete={type === 'password' ? 'new-password' : 'none'}
        value={meta.value}
        onChange={(e) => {
          update(e)
        }}
      />
    </>
  )
}

export default TextInput

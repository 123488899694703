import { useContext } from 'react'
import MainContext from '../../contexts/Main'
import { Form, useFormikContext } from 'formik'
import TextInput from './components/TextInput'

import logo_full from '../../assets/images/logo-full-reduce.png'
import logo_icon from '../../assets/images/logo_icon.png'
import logo_title from '../../assets/images/logo_title.png'
import logo_title_coloured from '../../assets/images/logo_title-coloured.png'

const Landing = () => {
  const { message, success } = useContext(MainContext)
  const formikData = useFormikContext()
  console.log(formikData)

  return (
    <div className='w-screen overflow-hidden flex flex-col items-center justify-center bg-gray-900'>
      <header className='relative flex flex-col items-center justify-center min-h-screen w-full  bg-gradient-to-b from-mpurple to-gray-900 '>
        <div className='spotlight'></div>
        <div className='spotlight'></div>
        <div className='spotlight'></div>
        <div className='relative z-10 flex flex-col items-center justify-center'>
          <img
            src={logo_full}
            alt='Logo'
            className='logo-reveal hidden md:flex md:w-1/2 xl:w-96'
          />
          <img
            src={logo_icon}
            alt='Logo'
            className='logo-reveal w-60 md:hidden'
          />
          <img
            src={logo_title}
            alt='Logo'
            className='logo-reveal w-64 md:hidden mt-2 mr-4'
          />
        </div>
        <div className='absolute bottom-8 z-10 animate-bounce logo-reveal'>
          <svg
            className='w-6 h-6 text-white'
            fill='none'
            stroke='currentColor'
            viewBox='0 0 24 24'
            xmlns='http://www.w3.org/2000/svg'
          >
            <path
              strokeLinecap='round'
              strokeLinejoin='round'
              strokeWidth='2'
              d='M19 9l-7 7-7-7'
            ></path>
          </svg>
        </div>
      </header>
      <main className='flex flex-col items-center justify-center min-h-screen bg-gray-900 w-full p-4 font-outfit text-mlight'>
        <h1 className='text-2xl xl:text-4xl font-bold mb-8 text-center uppercase'>
          ESTAMOS TERMINANDO DE DAR LUZ A NUESTRAS MUJERES
        </h1>
        <div className='max-w-2xl text-center'>
          <p className='mt-8 mb-2'>
            Cada historia cuenta, cada voz importa. Sin las mujeres, el relato
            histórico está incompleto. Mujeres en la Sombra es una plataforma de
            visibilidad y memoria que pretende terminar con la injusticia
            histórica de invisibilización de figuras femeninas. Te pedimos un
            poco de paciencia, ¡en nada estaremos listas!
          </p>
          <p className='mt-8'>
            "Ellas cambian el mundo. Aquí contamos sus historias."
          </p>
        </div>
        <img src={logo_title_coloured} alt='Logo' className='w-60 my-10' />
        <div className='max-w-md p-4'>
          <p className='text-sm uppercase font-bold text-center'>
            SUSCRÍBETE PARA ENTERARTE DE CUÁNDO ESTAMOS LISTAS.
          </p>
        </div>
        <Form className='relative flex flex-col items-center w-full max-w-md px-4 '>
          <TextInput
            id='email'
            name='email'
            label='Email'
            type='email'
            required={true}
            styled='w-full p-2 mb-4 focus:ring-mpink outline-none focus:ring-2 bg-mlight text-black rounded-md'
            placeholder='Ingresa tu correo electrónico'
          />
          <button
            disabled={success}
            type='submit'
            className='w-full p-2 text-mlight bg-mpurple rounded-md hover:bg-mpink disabled:opacity-50 disabled:hover:bg-mpurple'
          >
            Suscribirse
          </button>
          {message && (
            <p className='absolute -bottom-8 text-sm text-mpink font-outfit'>
              {message}
            </p>
          )}
        </Form>
      </main>
      <footer className='w-full h-20 flex justify-center items-end'>
        <p className='text-xs text-mlight mb-2'> Mujeres en La Sombra © 2024</p>
      </footer>
    </div>
  )
}

export default Landing

/* eslint-disable no-unused-vars */
import { useContext } from 'react'
import AdminContext from '../../contexts/Admin'
import ProfileMenu from './Widgets/ProfileMenu'
import { Transition } from '@headlessui/react'
import { views, headerIcons } from './Config/Navigation'
import { Bars3Icon, ChevronLeftIcon } from '@heroicons/react/24/outline'

const Header = () => {
  const { setMenuToggle, navigate, currentParams } = useContext(AdminContext)
  const { view, category, id } = currentParams

  return (
    <div className='flex justify-between items-center pt-6 px-6 z-30'>
      <div className='flex items-center space-x-4 lg:space-x-0'>
        <button
          className='text-gray-800 focus:outline-none lg:hidden'
          onClick={() => setMenuToggle(true)}
        >
          <Bars3Icon className='h-6 w-6' />
        </button>
        <div className='w-full flex items-center '>
          <Transition
            show={!!id}
            enter='transition-opacity ease-linear duration-700'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-75'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <button
              onClick={() => navigate(view)}
              className='group w-fit relative flex space-x-2 justify-center items-center border rounded-full border-mpink hover:bg-mlight text-outfit text-sm uppercase px-4 py-2 transition-all duration-300 ease-in-out'
            >
              <ChevronLeftIcon className='w-4 h-4' />
              <span>Back</span>
            </button>
          </Transition>
          <Transition
            show={!id}
            enter='transition-opacity ease-linear duration-700'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='transition-opacity ease-linear duration-75'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <div
              className={`${
                id ? 'hidden' : ''
              } flex justify-center items-center px-4 py-2 space-x-4 m-2`}
            >
              {headerIcons[views[view]?.icon]}
              <span className='text-grotesk text-lg md:text-xl'>
                {!views[view]?.categories
                  ? views[view]?.name
                  : views[view]?.categories[category]?.name}
              </span>
            </div>
          </Transition>
        </div>
      </div>
      <div className='flex items-center space-x-8'>
        <ProfileMenu />
      </div>
    </div>
  )
}

export default Header

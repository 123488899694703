import {
  ChevronDoubleLeftIcon,
  ChevronDoubleRightIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
} from '@heroicons/react/24/outline'

const TablePagination = ({ pageCount, currentPage, setPage }) => {
  const getPagination = () => {
    const pageLimit = pageCount > 5 ? 5 : pageCount
    let start = Math.floor((currentPage - 1) / pageLimit) * pageLimit + 1
    return new Array(pageLimit)
      .fill()
      .map((item, i) => (start + i <= pageCount ? start + i : null))
  }
  const previous = currentPage === 1 ? false : true
  const handlePrevious = () => {
    if (currentPage !== 1) setPage(currentPage - 1)
  }
  const next = currentPage === pageCount ? false : true
  const handleNext = () => {
    if (currentPage !== pageCount) setPage(currentPage + 1)
  }

  return (
    <div className='flex flex-col items-center'>
      <div className='flex text-gray-700'>
        <button
          onClick={() => setPage(1)}
          className={`h-8 w-8 mr-1 flex justify-center items-center ${
            previous
              ? 'hover:text-mpink cursor-pointer'
              : 'text-gray-100 cursor-not-allowed'
          }`}
        >
          <ChevronDoubleLeftIcon className='w-5 h-5' />
        </button>
        <button
          onClick={() => handlePrevious()}
          className={`h-8 w-8 mr-1 flex justify-center items-center ${
            previous
              ? 'hover:text-mpink cursor-pointer'
              : 'text-gray-100 cursor-not-allowed'
          }`}
        >
          <ChevronLeftIcon className='w-5 h-5' />
        </button>
        <div className='flex h-8 font-medium '>
          {getPagination().map((item, index) => {
            return item === null ? (
              <span
                key={index}
                className='w-10 text-lg text-grotesk justify-center items-center '
              ></span>
            ) : (
              <button
                onClick={() => setPage(item)}
                key={index}
                className={`w-10 text-lg text-grotesk justify-center items-center cursor-pointer transition duration-150 ease-in border-t-2  ${
                  currentPage === item
                    ? 'border-mpink flex'
                    : 'border-transparent md:flex hidden hover:text-mpink'
                }`}
              >
                {item}
              </button>
            )
          })}
        </div>
        <button
          onClick={() => handleNext()}
          className={`h-8 w-8 mr-1 flex justify-center items-center ${
            next
              ? 'hover:text-mpink cursor-pointer'
              : 'text-gray-100 cursor-not-allowed'
          }`}
        >
          <ChevronRightIcon className='w-5 h-5 ' />
        </button>
        <button
          onClick={() => setPage(pageCount)}
          className={`h-8 w-8 mr-1 flex justify-center items-center ${
            next
              ? 'hover:text-mpink cursor-pointer'
              : 'text-gray-100 cursor-not-allowed'
          }`}
        >
          <ChevronDoubleRightIcon className='w-5 h-5 ' />
        </button>
      </div>
    </div>
  )
}

export default TablePagination

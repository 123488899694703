import './style/index.css'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import AdminProvider from './contexts/AdminProvider'
import MainProvider from './contexts/MainProvider'
import Forgot from './components/Admin/Settings/Forgot'
import { useEffect } from 'react'

function App() {
  const proxy_name =
    process.env.PROXY_NAME && process.env.PROXY_NAME !== ''
      ? '/' + process.env.PROXY_NAME
      : ''
  const isHomeRoute = window.location.pathname === proxy_name + '/'

  useEffect(() => {
    // Add or remove a class based on the route
    document.body.classList.toggle('home-route', isHomeRoute)
    document.body.classList.toggle('other-route', !isHomeRoute)
  }, [isHomeRoute])

  return (
    <BrowserRouter basename={proxy_name}>
      <Routes>
        <Route path='/*' element={<MainProvider />} />
        <Route path='/admin/*' element={<AdminProvider />} />
        <Route path='/admin/verify/:token?' element={<Forgot />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App

import Admin from '../components/Admin/Admin'
import AdminContext from './Admin'
import { useState, useEffect, useMemo, useRef } from 'react'
import { useMatch, useNavigate, useParams } from 'react-router-dom'
import { getParams, renewToken } from '../components/Common/Utils/navigation'
import { views } from '../components/Admin/Config/Navigation'
import jwt from 'jwt-decode'

const AdminProvider = () => {
  const navigate = useNavigate()
  const match = useMatch('admin')
  const params = useParams()
  const [token, setToken] = useState()
  const [user, setUser] = useState(null)
  const [menuToggle, setMenuToggle] = useState(false)
  const [mainBanner, setMainBanner] = useState({ show: false, message: null })
  const [showModal, setShowModal] = useState(false)
  const [currentParams, setCurrentParams] = useState({})
  const [reload, setReload] = useState(0)
  const [selectedTab, setSelectedTab] = useState(0)
  const [status, setStatus] = useState('')
  const [offset, setOffset] = useState(0)
  const pageTop = useRef()

  //Auth config
  const config = useMemo(() => {
    return {
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      },
    }
  }, [token])

  //Utilities
  const scrollUp = () => {
    pageTop.current.scrollIntoView({ behavior: 'smooth' })
  }

  const getUuid = () => {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (
        c ^
        (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
      ).toString(16)
    )
  }

  const avatarInitials = (name) => {
    const names = name.split(' ')
    let initials = names[0].substring(0, 1).toUpperCase()
    if (names.length > 1) {
      initials += names[names.length - 1].substring(0, 1).toUpperCase()
    }
    return initials
  }

  const clearTimeOut = () => {
    let timeOutIds = window.setTimeout(function () {}, 0)
    while (timeOutIds--) {
      window.clearTimeout(timeOutIds)
    }
  }

  const displayMainMessage = (message, time) => {
    setMainBanner({ ...message, show: true })
    setTimeout(() => {
      setMainBanner({ ...message, show: false })
    }, time)
  }

  const logout = () => {
    localStorage.MAdminAuthToken = ''
    setUser(null)
    setToken(null)
    navigate('/admin')
  }

  const checkAuthToken = async () => {
    return await renewToken(setToken)
  }

  //At state changes
  useEffect(() => {
    const checkAuthToken = async () => {
      if (localStorage.MAdminAuthToken) {
        setToken(localStorage.MAdminAuthToken)
        const decode = jwt(localStorage.MAdminAuthToken)
        const now = Math.floor(Date.now() / 1000)
        if (decode.sub) {
          if (decode.exp < now) logout()
          setUser({
            id: decode.sub,
            name: decode.name,
            role: decode.role,
            version: process.env.REACT_APP_VERSION,
          })
          if (match) navigate('/admin/dashboard')
        }
      } else {
        setUser(null)
        setToken(null)
      }
    }
    checkAuthToken()
  }, [token])

  useEffect(() => {
    setCurrentParams(getParams(params))
  }, [params])

  return (
    <AdminContext.Provider
      value={{
        pageTop,
        scrollUp,
        displayMainMessage,
        menuToggle,
        setMenuToggle,
        user,
        setUser,
        checkAuthToken,
        views,
        selectedTab,
        setSelectedTab,
        mainBanner,
        setMainBanner,
        currentParams,
        setCurrentParams,
        logout,
        token,
        setToken,
        status,
        setStatus,
        reload,
        setReload,
        config,
        avatarInitials,
        clearTimeOut,
        offset,
        setOffset,
        navigate,
        getUuid,
        showModal,
        setShowModal,
      }}
    >
      <Admin />
    </AdminContext.Provider>
  )
}

export default AdminProvider

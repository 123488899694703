import { PropTypes } from 'prop-types'
import { Link } from 'react-router-dom'
import { getTimeDifference } from '../../Common/Utils/display'
import { EyeIcon, PencilIcon } from '@heroicons/react/24/outline'

const TableRow = ({ item, headers, link }) => {
  // Construct based on type
  const designField = (field, i) => {
    if (headers[i].type === 'array') {
      const extra = field?.length - 2

      let itemsDisplay = []
      if (field?.length <= 2) {
        itemsDisplay = [
          ...field.map((el, e) => {
            return (
              <span className={`${headers[i].rstyle}`} key={el + item.id}>
                {el}
              </span>
            )
          }),
        ]
      } else {
        for (let e = 0; e < 2; e++) {
          itemsDisplay.push(
            <span
              key={headers[i].key + e + item.id}
              className={` ${headers[i].rstyle}`}
            >
              {field[e]}
            </span>
          )
        }
        if (extra > 0) {
          itemsDisplay.push(
            <span
              key={item.id + headers[i].key}
              className='max-w-fit max-h-6 whitespace-nowrap px-2 py-0.5 rounded-sm text-xs border justify-center items-center flex bg-gray-200 border-gray-400'
            >
              + {extra}
            </span>
          )
        }
      }
      if (field?.length === 0)
        itemsDisplay.push(
          <p key={headers[i].key + item.id} className='text-sm'>
            -
          </p>
        )
      return itemsDisplay
    }
    if (headers[i].type === 'text') {
      if (headers[i].key === 'locale') {
        let displayField
        switch (field) {
          case 'en':
            displayField = '🇬🇧'
            break
          case 'de':
            displayField = '🇩🇪'
            break
          case 'fr':
            displayField = '🇫🇷'
            break
          case 'es':
            displayField = '🇪🇸'
            break
          default:
            displayField = field
        }
        return <p className={headers[i].rstyle}>{displayField}</p>
      } else {
        return (
          <p className={headers[i].rstyle}>{field?.length ? field : '-'}</p>
        )
      }
    }
    if (headers[i].type === 'number') {
      return <p className={headers[i].rstyle}>{field?.length ? field : '-'}</p>
    }
    if (headers[i].type === 'date') {
      return <p>{getTimeDifference(field)}</p>
    }
    if (headers[i].type === 'image') {
      return <span>img</span>
    }
    if (headers[i].type === 'object') {
      const finalContent = []
      for (const el in field) {
        finalContent.push(
          <p
            key={field[el].value + item.id}
            className={
              field[el].style[field[el].value]
                ? field[el].style[field[el].value]
                : field[el].style
            }
          >
            {field[el].value}
          </p>
        )
      }
      return <div className='flex space-x-2 justify-center'>{finalContent}</div>
    }
    if (headers[i].type === 'boolean') {
      return (
        <p className={headers[i].rstyle}>
          {field ? (
            <span className='text-green-500'>Yes</span>
          ) : (
            <span className='text-red-500'>No</span>
          )}
        </p>
      )
    }
  }
  return (
    <div className='w-full flex border-b border-grey-light hover:bg-gray-100'>
      {headers.map((header, i) =>
        !header.key.includes('$') ? (
          <div key={header.key + item.id} className={header.style}>
            {designField(item[header.key], i)}
          </div>
        ) : (
          <div key={header.key + item.id} className={`p-2 ${header.style}`}>
            <Link
              to={link ? link + item.id : `${link}/${item.id}`}
              className='mx-auto w-6 flex item-center justify-center transform transition duration-200 hover:text-mpink'
            >
              {header.key.includes('edit') ? (
                <PencilIcon className='h-5 w-5' />
              ) : (
                <EyeIcon className='h-5 w-5' />
              )}
            </Link>
          </div>
        )
      )}
    </div>
  )
}

TableRow.propTypes = {
  name: PropTypes.string,
  item: PropTypes.object,
  headers: PropTypes.array,
}

export default TableRow

import { useState, useEffect } from 'react'
import axios from 'axios'
import * as yup from 'yup'
import { Formik } from 'formik'

import MainContext from './Main'
import Landing from '../components/Landing/Landing'

import {
  checkVersionUrl,
  signUpURL,
} from '../components/Landing/config/APIurls'

const MainProvider = () => {
  const locale = navigator.language.slice(0, 2)
  const [reload, setReload] = useState(0)
  const [success, setSuccess] = useState(false)
  const [message, setMessage] = useState('')

  //FORM REGISTRY
  const initialValues = {
    email: '',
    locale: locale,
  }
  const validationSchema = yup.object().shape({
    email: yup.string().email('invalidemailerror').required('requirederror'),
  })

  useEffect(() => {
    //Check version and reload on new
    const checkVersion = async () => {
      const { data } = await axios.get(checkVersionUrl, {
        header: {
          'Content-Type': 'application/json',
        },
      })
      if (data.version !== process.env.REACT_APP_VERSION) {
        window.location.reload(true)
      }
    }
    checkVersion()
  }, [])

  const submitForm = async (values, actions) => {
    try {
      let submittedValues = { ...values }
      const config = {
        header: {
          'Content-Type': 'application/json',
        },
      }
      const response = await axios.post(signUpURL, submittedValues, config)
      const data = response.data
      if (data.id) {
        actions.setSubmitting(false)
        setMessage(
          'Gracias por tu interés, te informaremos cuando estemos en línea.'
        )
        setSuccess(true)
      } else {
        actions.setSubmitting(false)
      }
    } catch (err) {
      actions.setSubmitting(false)
      if (err.code === 'ERR_BAD_REQUEST') {
        if (err.response.data.message === 'Email already taken') {
          setMessage('Gracias por tu interés, ¡tu email ya está registrado!')
        } else {
          setMessage('Algo salió mal, intenta de nuevo.')
        }
      } else {
        setMessage('Algo salió mal, intenta de nuevo.')
      }
    }
  }

  return (
    <MainContext.Provider
      value={{
        locale,
        reload,
        setReload,
        success,
        message,
      }}
    >
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        validateOnChange={false}
        enableReinitialize={true}
        initialTouched={{}}
        initialErrors={{}}
        validateOnMount={true}
        onSubmit={submitForm}
      >
        <Landing />
      </Formik>
    </MainContext.Provider>
  )
}

export default MainProvider

import { Link } from 'react-router-dom'

const PageNotFound = () => {
  return (
    <>
      <div className='max-w-screen-xl flex flex-grow mx-auto'>
        <div className='w-full flex flex-col items-center justify-center h-screen'>
          <h1 className='text-4xl font-bold mb-4'>Oh No!</h1>
          <p className='text-gray-500 text-center mb-8'>
            Todavía en construcción. 👷‍♀️
          </p>
          <Link
            to={'/admin/dashboard'}
            className='bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded'
          >
            Volver
          </Link>
        </div>
      </div>
    </>
  )
}

export default PageNotFound

import { useContext } from 'react'
import AdminContext from '../../contexts/Admin'
import Login from './Login'
import Main from './Main'

const Admin = () => {
  const { user } = useContext(AdminContext)
  return user ? <Main /> : <Login />
}

export default Admin

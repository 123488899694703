import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'

const Popup = ({ popUp, closePopUp, title, content, message }) => {
  return (
    <Transition show={popUp} as={Fragment}>
      <Dialog
        as='div'
        className='fixed inset-0 z-50 overflow-y-auto'
        onClose={closePopUp}
      >
        <div className='min-h-screen px-4 text-center'>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0'
            enterTo='opacity-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100'
            leaveTo='opacity-0'
          >
            <Dialog.Overlay className='fixed inset-0 bg-white bg-opacity-20 backdrop-blur-lg transition-opacity ' />
          </Transition.Child>
          <span
            className='inline-block h-screen align-middle'
            aria-hidden='true'
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block w-full max-w-2xl p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-2xl z-50'>
              <Dialog.Title
                as='h3'
                className='text-outfit text-lg font-bold leading-6 text-gray-900'
              >
                {title}
              </Dialog.Title>
              <div
                className='flex flex-col p-2 justify-center my-2 overflow-hidden '
                dangerouslySetInnerHTML={{ __html: content }}
              ></div>
              <div className='mt-4 flex justify-center items-center'>
                <button
                  type='button'
                  className='mx-auto inline-flex justify-center px-4 py-2 text-sm font-medium text-black bg-mpurple border border-transparent hover:bg-mpink hover:text-white focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-mpink'
                  onClick={closePopUp}
                >
                  {message}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  )
}

export default Popup

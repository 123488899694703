import { useContext } from 'react'
import AdminContext from '../../../contexts/Admin'

const icons = {
  profile: '👤',
  members: '👥',
  users: '👨‍💻',
  config: '⚙️',
  logs: '📜',
  notifications: '🔔',
  about: '📖',
}

const Tabs = ({ tabs, id, setData }) => {
  const { user, selectedTab, setSelectedTab } = useContext(AdminContext)
  let color = id ? 'gray-400' : 'black'

  return (
    <div className='w-full sticky top-0 z-20'>
      <div
        className={`${
          id ? 'cursor-not-allowed opacity-20' : 'cursor-pointer'
        } mt-0 mr-auto border-b-4 border-black w-full transition ease-in-out duration-200 flex outline-none justify-start overflow-hidden`}
      >
        <ul className='flex m-0 p-0 float-left list-none overflow-x-scroll scrollbar-thin scrollbar-track-gray-300 scrollbar-thumb-gray-900'>
          {tabs?.map((tab) =>
            user.role.includes(tab.restricted) ? (
              <li
                key={tab.name}
                className={`block float-right pt-1 px-2 pb-1 min-w-max transition ease-in-out duration-200 text-${color} text-outfit tracking-wide outline-none flex justify-center items-center ${
                  selectedTab === tab.index
                    ? 'bg-black shadow text-white'
                    : 'bg-white text-gray-400 hover:text-black hover:bg-gray-200 border-t border-x border-gray-200'
                } before:border-r-2`}
                onClick={() => {
                  if (id || tab.name.includes('@')) {
                    return
                  } else {
                    setData({ results: [], totalPages: 0 })
                    setSelectedTab(tab.index)
                  }
                }}
              >
                {tab.icon ? (
                  <div className='flex w-5 h-5 items-center justify-center'>
                    {icons[tab.icon](
                      `w-5 h-full text-${tab.color} fill-current `,
                      `${
                        tab.color === 'mpurple' ? 'text-black' : 'text-white'
                      } fill-current`,
                      0
                    )}
                  </div>
                ) : (
                  ''
                )}
                {tab.name.includes('@') ? (
                  tab.component
                ) : (
                  <span className='px-3 py-0.5 text-xs md:text-md'>
                    {tab.name}
                  </span>
                )}
              </li>
            ) : (
              ''
            )
          )}
        </ul>
      </div>
    </div>
  )
}

export default Tabs

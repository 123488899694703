import moment from 'moment'

export const getTimeDifference = (DateToCompare) => {
  const today = Date.now()
  const compareDate = Date.parse(DateToCompare)
  // getting hours
  let timeDifference = (today - compareDate) / 1000 / 60 / 60
  if (timeDifference >= 24) {
    // getting days
    timeDifference /= 24
    // if less than 60 days shows num of days, if more shows the full date
    timeDifference =
      timeDifference <= 60
        ? `${parseInt(timeDifference)}d ago`
        : moment(DateToCompare).format('DD/M/YYYY')
  } else if (timeDifference < 24 && timeDifference > 0.99) {
    // shows hours
    timeDifference = `${Math.round(timeDifference)}h ago`
  } else {
    // shouws minutes
    timeDifference = `${parseInt((timeDifference *= 60))}m ago`
    if (timeDifference === '0 m ago') {
      timeDifference = 'now'
    }
  }
  return timeDifference
}

export const isRunningStandalone = () =>
  window.matchMedia('(display-mode: standalone)').matches

export const getMonthsLabel = (n) => {
  const d = new Date()
  const currentMonth = d.getMonth()
  const currentYear = d.getFullYear()
  const locale = 'en-GB'

  const result = []
  for (let i = n; i > 0; i--) {
    d.setMonth(currentMonth - i)
    d.getMonth() > currentMonth
      ? d.setFullYear(currentYear - 1)
      : d.setFullYear(currentYear)
    result.push(
      `${d.toLocaleDateString(locale, {
        month: 'short',
      })}/${d.toLocaleDateString(locale, { year: '2-digit' })}`
    )
  }
  result.push(
    `${new Date().toLocaleDateString(locale, {
      month: 'short',
    })}/${d.toLocaleDateString(locale, { year: '2-digit' })}`
  )
  return result
}

import { Doughnut, Bar, Pie } from 'react-chartjs-2'
import 'chart.js/auto'
import Loading from '../../Common/Widgets/Loading'

const ChartWidget = ({ data, loading, refVrb, onClickFn }) => {
  return (
    <div className='w-full mb-4'>
      <div className='bg-white md:h-96 shadow-md md:shadow-lg'>
        <div className='w-full h-full flex flex-col px-3 py-4 text-center'>
          <h4 className='text-sm uppercase text-gray-500 leading-tight my-3'>
            {data?.label}
          </h4>
          <div className='flex-grow px-8'>
            {loading ? (
              <Loading />
            ) : data.type === 'doughnut' ? (
              <Doughnut
                data={data.data}
                options={data.options}
                ref={refVrb}
                onClick={onClickFn}
              />
            ) : data.type === 'pie' ? (
              <Pie
                data={data.data}
                options={data.options}
                ref={refVrb}
                onClick={onClickFn}
              />
            ) : (
              <Bar
                data={data.data}
                options={data.options}
                ref={refVrb}
                onClick={onClickFn}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChartWidget

/* eslint-disable no-unused-vars */
import {
  NewspaperIcon,
  HomeIcon,
  UserGroupIcon,
  UsersIcon,
  AdjustmentsHorizontalIcon,
  RectangleStackIcon,
} from '@heroicons/react/24/outline'

export const views = {
  dashboard: { icon: 'home', name: 'Panel', color: 'mpurple' },
  blog: { icon: 'blog', name: 'Blog', color: 'myellow' },
  mujeres: { icon: 'mujeres', name: 'Mujeres', color: 'myellow' },
  members: { icon: 'groupuser', name: 'Suscriptores', color: 'mpink' },
  settings: { icon: 'set', name: 'Ajustes', color: 'mpink' },
}

export const headerIcons = {
  home: <HomeIcon className='block h-6 w-6 text-mpurple' />,
  blog: <NewspaperIcon className='block h-6 w-6 text-myellow' />,
  mujeres: <UserGroupIcon className='block h-6 w-6 text-myellow' />,
  groupuser: <UsersIcon className='block h-6 w-6 text-mpink' />,
  set: <RectangleStackIcon className='block h-6 w-6 text-mpink' />,
}

export const menuIcons = {
  home: <HomeIcon className='h-5 w-5 group-hover:text-mpurple' />,
  blog: <NewspaperIcon className='h-5 w-5 group-hover:text-myellow' />,
  mujeres: <UserGroupIcon className='h-5 w-5 group-hover:text-myellow' />,
  groupuser: <UsersIcon className='h-5 w-5 group-hover:text-mpink' />,
  set: <AdjustmentsHorizontalIcon className='h-5 w-5 group-hover:text-mpink' />,
}

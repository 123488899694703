const TableHeader = ({ headers }) => {
  return (
    <div className='flex justify-between text-left bg-gray-300 border-b border-grey uppercase'>
      {headers.map((header) => (
        <span
          key={header.key}
          className={`text-gray-700 text-sm leading-tight uppercase px-2 py-6 text-center ${header.style}`}
        >
          {header.title}
        </span>
      ))}
    </div>
  )
}

export default TableHeader

// API URLS
const proxy_name =
  process.env.PROXY_NAME && process.env.PROXY_NAME !== ''
    ? '/' + process.env.PROXY_NAME
    : ''

//AUTH
export const loginUrl = proxy_name + '/api/v1/auth/login/'
export const forgotPasswordUrl = proxy_name + '/api/v1/auth/forgot-password/'
export const refreshTokenUrl = proxy_name + '/api/v1/auth/refresh-tokens/'
export const sendVerification =
  proxy_name + '/api/v1/auth/send-verification-email/'
export const updatesUrl = proxy_name + '/api/auth/updates'
export const verifyUrl = proxy_name + '/api/v1/auth/verify-email'

//DASHBOARD
export const checkVersionUrl = proxy_name + '/api/v1/auth/version/'

//MEMBERS MNGT
export const getMembersUrl = proxy_name + '/api/v1/members/all/'
export const getMembersPageUrl = proxy_name + '/api/v1/members/'
export const getMemberUrl = proxy_name + '/api/v1/members/id/'
export const downloadMembersUrl = proxy_name + '/api/v1/members/download/'
//USERS MNGT
export const getUsersUrl = proxy_name + '/api/v1/users/'

// Path: react-app/src/components/Admin/Config/APIurls.js

/* eslint-disable no-unused-vars */
import { useContext } from 'react'
import AdminContext from '../../contexts/Admin'
import { views, menuIcons } from './Config/Navigation'
import { Disclosure, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'

const Menu = () => {
  const {
    setMenuToggle,
    menuToggle,
    setSelectedTab,
    setOffset,
    navigate,
    currentParams,
  } = useContext(AdminContext)
  const { view, category } = currentParams

  const getCategories = (view) => {
    return Object.keys(views[view].categories).map((key) => (
      <button
        onClick={() => handleMenu(view, key)}
        key={key}
        className={` group border-l-4 text-left py-2.5 pl-6 w-full transition duration-200 hover:bg-white hover:text-black flex items-center text-xs ${
          category === key
            ? 'border-gray-200 bg-gray-300 text-black'
            : 'border-transparent'
        } `}
      >
        {menuIcons[views[view].categories[key].icon]}
        <span className='ml-3 px-2'>{views[view].categories[key].name}</span>
      </button>
    ))
  }

  const handleMenu = (view, key) => {
    let path = '/admin/' + view
    path += key ? '/' + key : ''
    setMenuToggle(false)
    setSelectedTab(0)
    setOffset(0)
    navigate(path)
  }

  return (
    <>
      <div
        className={`${
          menuToggle ? 'block ' : 'hidden'
        } fixed z-40 inset-0 bg-white bg-opacity-20 backdrop-blur-lg transition-opacity lg:hidden`}
        onClick={() => setMenuToggle(false)}
      ></div>
      <div
        className={`fixed z-40 inset-y-0 left-0 w-72 transition duration-300 transform bg-black overflow-y-auto ${
          !menuToggle && '-translate-x-full'
        } lg:translate-x-0 lg:static lg:inset-0`}
      >
        <p className='text-outfit text-white text-center mt-2'>
          Mujeres en La Sombra
        </p>
        <p className='text-mpink text-center'>Admin Panel</p>
        <nav className='text-white text-outfit pt-6 text-md'>
          {Object.keys(views).map((key, index) =>
            views[key].categories ? (
              <Disclosure key={key + index}>
                {({ open }) => (
                  <>
                    <Disclosure.Button
                      className={` group border-l-4 text-left py-2.5 px-4 w-full transition duration-200 hover:bg-white hover:text-black flex text-sm items-center ${
                        view === key
                          ? 'border-gray-200 bg-white text-black'
                          : 'border-transparent'
                      } `}
                    >
                      {menuIcons[views[key].icon]}
                      <span className='ml-3 px-2 flex-grow'>
                        {views[key].name}
                      </span>
                      <ChevronDownIcon
                        className={`h-4 w-4 ${
                          open
                            ? 'transition duration-200 transform rotate-180'
                            : 'transition duration-200 transform rotate-0'
                        }`}
                      />
                    </Disclosure.Button>
                    <Transition
                      enter='transition ease-in-out duration-300 transform'
                      enterFrom='-translate-x-full'
                      enterTo='translate-x-0'
                      leave='transition ease-in-out duration-300 transform'
                      leaveFrom='translate-x-0'
                      leaveTo='-translate-x-full'
                    >
                      <Disclosure.Panel className='text-white '>
                        {getCategories(key)}
                      </Disclosure.Panel>
                    </Transition>
                  </>
                )}
              </Disclosure>
            ) : (
              <button
                key={key + 'L' + index}
                onClick={() => handleMenu(key, null)}
                className={`group border-l-4  text-left py-2.5 px-4 w-full transition duration-200 hover:bg-white hover:text-black  flex items-center text-sm tracking-wider ${
                  view === key
                    ? 'border-mpink bg-white text-black'
                    : 'border-transparent'
                } `}
              >
                {menuIcons[views[key].icon]}
                <span className='ml-3 px-2'>{views[key].name}</span>
              </button>
            )
          )}
        </nav>
      </div>
    </>
  )
}

export default Menu

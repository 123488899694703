import { useEffect, useState, useContext } from 'react'
import axios from 'axios'
import AdminContext from '../../../contexts/Admin'
import Loading from '../../Common/Widgets/Loading'
import UpdateDetails from './UpdateDetails'
import { FaceFrownIcon } from '@heroicons/react/24/outline'
import { updatesUrl } from '../Config/APIurls'

const UpdateList = () => {
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true)
  const { reload, config, checkAuthToken, navigate } = useContext(AdminContext)
  useEffect(() => {
    const getData = async () => {
      try {
        if (await !checkAuthToken()) return navigate('/login')
        const updatesList = await axios.get(updatesUrl, config)
        if (updatesList.data.data) setData([...updatesList.data.data])
        setLoading(false)
      } catch (e) {}
    }
    getData()
  }, [reload])

  return loading ? (
    <Loading />
  ) : (
    <div className='flex flex-col pr-4 pt-2 w-full justify-start overflow-y-scroll scrollbar scrollbar-thin scrollbar-track-blue-100 scrollbar-thumb-blue-500'>
      {data.length ? (
        data.map((update) => <UpdateDetails key={update._id} update={update} />)
      ) : (
        <div className='font-medium flex space-x-4 items-center my-2 ml-2'>
          <FaceFrownIcon className='h-6 w-6' />
          <p>Nada que mostrar</p>
        </div>
      )}
    </div>
  )
}

export default UpdateList

import MembersList from './MembersList'

const Members = () => {
  return (
    <div className='relative h-full w-full flex px-4 md:px-8 items-center flex-col bg-white outline-none overflow-y-scroll'>
      <MembersList />
    </div>
  )
}

export default Members

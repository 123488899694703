import { Transition } from '@headlessui/react'
import {
  CheckCircleIcon,
  ExclamationCircleIcon,
  XMarkIcon,
} from '@heroicons/react/24/outline'

const Banner = ({ message, setBanner }) => {
  return (
    <Transition
      as='div'
      show={message.show}
      enter='transition ease-in-out duration-300 transform'
      enterFrom='-translate-y-full opacity-0'
      enterTo='opacity-100 translate-y-0'
      leave='transition ease-in-out duration-300 transform'
      leaveFrom='translate-y-0 opacity-100'
      leaveTo='-translate-y-full opacity-0'
      className={`relative font-bold z-50 ${
        message.success
          ? 'text-green-700 bg-green-100 border-green-400 '
          : 'text-red-700 bg-red-200 border-red-400 '
      } border flex space-x-2 items-center text-grotesk px-2 py-2 rounded`}
    >
      {message.success ? (
        <CheckCircleIcon className='w-8' />
      ) : (
        <ExclamationCircleIcon className='w-8' />
      )}
      <p className='text-sm'>{message.message}</p>
      <XMarkIcon
        className='mx-1 w-4'
        onClick={() => {
          setBanner({ ...message, show: false })
        }}
      />
    </Transition>
  )
}

export default Banner
